<template>
  <div>
    <v-list-item-subtitle class="text-wrap"></v-list-item-subtitle>
    <div class="mt-4">
      <v-divider></v-divider>
      <AdminLayoutTable
        table_title="Points"
        :headers="headerProps"
        :items="tableItems"
        v-on:edit_item="edit_item"
        v-on:delete_item="confirm_delete"
        item_key="title"
        v-model="selected"
        :clipboard-key="'title'"
      >
        <template v-slot:importer>
          <csv-importer-view
            :import_headers="import_headers"
            button-title="Create Points"
            item-type="Point"
            v-on:save-import="saveImport"
          ></csv-importer-view>
        </template>
      </AdminLayoutTable>
      <confirmation-dialog ref="confirm" />
      <user-editor
        ref="user_editor"
        :user-info="selected_user_for_edit"
      ></user-editor>
    </div>
  </div>
</template>

<script>
import AdminLayoutTable from "@/components/AdminLayoutTable";
import user_editor from "@/components/user_editor";
import CSVImporterView from "@/components/csvData/CSVImporterView";
export default {
  //headers are supplied as a list of objects
  //Text is the display name/text is mapped to the item(s) key { text: "name", value: "name" },

  name: "AdminPointLayout",
  components: {
    AdminLayoutTable,
    "user-editor": user_editor,
    "csv-importer-view": CSVImporterView,
    confirmationDialog: () => import("@/components/confirmationDialog")
  },
  props: {},
  data() {
    return {
      dialog: false,
      formTitle: "My form title",
      tab: "tab data",
      headerProps: [
        { text: "Point Name", value: "title" },
        { text: "Point Value", value: "value" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      search: "",
      editedItem: {},
      items_per_page: 20,
      sort_key: "last_name",
      selected_index: -1,
      selected_user_for_edit: null,
      last_seen: null,
      selected: [],
      import_headers: [
        {
          slug: "activity",
          required: true,
          tooltip: `e.g. attendance
          The base for all point titles, this is used for analytics and the entire point system.
          `
        },
        {
          slug: "value",
          required: true,
          tooltip: `e.g. 10
          This is the value that a user will receive if they get this point.`
        },
        {
          slug: "location",
          required: false,
          tooltip: `e.g. main-stage/925
          no '/' in front!
          Use location to make this point unique to a particular Eventfinity page`
        },
        {
          slug: "date",
          required: false,
          tooltip: `e.g. 2021-01-20
          Must be this ISO format. 
          Use this to make this point unique across
          seperate dates`
        },
        {
          slug: "item",
          required: false,
          tooltip: `e.g. booth_100
          Use this to make points unique if all other headers are the same`
        }
      ]
    };
  },
  methods: {
    close() {},
    saveImport(csvData) {
      console.log("running update points");
      let pointObjs = [];
      for (const point of csvData) {
        let pointStrParts = [];

        /**
         * * we'll construct a point string here based on any items the user has
         * * supplied. the only required fields are activity and value
         */

        // * add activity - it's required
        if ("activity" in point) {
          pointStrParts.push(point.activity);
        } else {
          throw "No activity added. Activity is a required field";
        }
        if ("value" in point === false) {
          throw "No value added! It's required!";
        }

        // * add location - it's optional
        if ("location" in point) {
          pointStrParts.push(encodeURIComponent(point.location));
        } else {
          pointStrParts.push("");
        }

        // * add date - it's optional
        if ("date" in point) {
          pointStrParts.push(point.date);
        } else {
          pointStrParts.push("");
        }

        // * add item - it's optional
        if ("item" in point) {
          pointStrParts.push(point.item);
        } else {
          pointStrParts.push("");
        }
        let finalPointStr = pointStrParts.join("++");

        let finalPoint = {
          title: finalPointStr,
          value: point.value
        };
        pointObjs.push(finalPoint);

        console.log(point.title);
        this.$store.commit("add_point", finalPoint);
      }
      console.log(csvData);
      this.$store.dispatch("update_points");
      return pointObjs;
    },
    save() {},
    process_data() {},
    process_import() {},
    initialize() {},
    addNewPoint: function() {
      let tempPointMap = {
        title: this.newPointText,
        value: this.newPointValue
      };
      this.$store.commit("add_point", tempPointMap);
      this.has_unsaved_changes = true;
      return false;
    },
    remove_point(point, selection) {
      // this.has_unsaved_changes = true;
      if (selection.length > 0) {
        selection.map(point => {
          this.$store.commit("remove_point", point.title);
        });
        console.log(selection);
      } else {
        this.$store.commit("remove_point", point.title);
      }
      this.$store.dispatch("update_points");
    },
    submitPoints() {
      this.$store.dispatch("update_points");
      this.has_unsaved_changes = false;
    },
    update_points(data) {
      console.log(data);
      this.has_unsaved_changes = true;
      this.$store.commit("edit_point", data);
    },
    edit_item(userObj) {
      this.selected_user_for_edit = userObj;
      this.dialog = true;
      console.log(userObj);
      // this.$nextTick(function() {
      //   this.$refs.user_editor.show_modal();
      // });
    },
    async confirm_delete(item, selection) {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to delete this record?"
        )
      ) {
        this.remove_point(item, selection);
      }
    }
  },
  computed: {
    allItems() {
      return this.$store.state.user_list;
    },
    items() {
      return this.$store.state.user_list;
    },
    tableItems() {
      let pointObjs = [];
      for (const [key, value] of Object.entries(this.$store.state.point_map)) {
        let point = {};
        point.title = key;
        point.value = value;
        pointObjs.push(point);
      }
      return pointObjs;
    },
    point_map() {
      return this.$store.state.point_map;
    }
  },
  mounted() {
    console.log("hello");
    this.$store.commit("clear_points");
    this.$store.dispatch("get_points");
  },
  created() {
    this.$store.watch(
      state => {
        return this.$store.state.client_selection;
      },
      (newValue, oldValue) => {
        this.$store.commit("clear_points");
        this.$store.dispatch("get_points");
      },
      {
        deep: true
      }
    );
  }
};
</script>
<style scoped></style>
